import React, { ElementType, Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ThemeProvider from "src/theme";
import "./font.css";

const ToTop: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/fan-club" &&
      location.search.startsWith("?type=")
    )
      return;
    if (
      location.pathname === "/match-info" &&
      location.search.startsWith("?type=Result&filter=")
    )
      return;
    if (
      location.pathname === "/activity-topics" &&
      location.search.startsWith("?filter=")
    )
      return;

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);

  return <></>;
};

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <ThemeProvider>
      <Suspense>
        <ToTop />
        <Component {...props} />
      </Suspense>
    </ThemeProvider>
  );
};

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/match-info" element={<MatchInfo />} />
          <Route path="/result-detail" element={<ResultDetail />} />
          <Route path="/activity-topics" element={<ActivityTopic />} />
          <Route
            path="/activity-topic-detail"
            element={<ActivityTopicDetail />}
          />
          <Route path="/academy" element={<Academy />} />
          <Route path="/noto-ishikawa" element={<NotoIshikawa />} />
          <Route path="/sponsor" element={<Sponsor />} />
          <Route path="/team-schedule" element={<TeamSchedule />} />
          <Route path="/team-info" element={<TeamInformation />} />
          <Route
            path="/player-staff-info"
            element={<PlayerStaffInformation />}
          />
          <Route
            path="/player-staff-info-detail"
            element={<PlayerStaffInformationDetail />}
          />
          <Route path="/copyright-and-links" element={<CopyrightAndLinks />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/fan-club" element={<FanClub />} />
          <Route
            path="/official-supporter-application"
            element={<OfficialSupporterApplication />}
          />
          <Route path="/goods-store" element={<GoodsStore />} />
          <Route path="/pdf" element={<Pdf />} />
          <Route path="/email-completed" element={<EmailCompleted />} />
          <Route path="/email-checked" element={<EmailChecked />} />
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
const Home = Loadable(lazy(() => import("./pages/home")));
const MatchInfo = Loadable(lazy(() => import("./pages/match-info")));
const ResultDetail = Loadable(lazy(() => import("./pages/result-detail")));
const ActivityTopic = Loadable(lazy(() => import("./pages/activity-topic")));
const ActivityTopicDetail = Loadable(
  lazy(() => import("./pages/activity-topic-detail"))
);
const Academy = Loadable(lazy(() => import("./pages/academy")));
const NotoIshikawa = Loadable(lazy(() => import("./pages/noto-ishikawa")));
const Sponsor = Loadable(lazy(() => import("./pages/sponsor")));
const TeamSchedule = Loadable(lazy(() => import("./pages/team-schedule")));
const TeamInformation = Loadable(lazy(() => import("./pages/team-info")));
const PlayerStaffInformation = Loadable(
  lazy(() => import("./pages/player-staff-info"))
);
const PlayerStaffInformationDetail = Loadable(
  lazy(() => import("./pages/player-staff-info-detail"))
);
const CopyrightAndLinks = Loadable(
  lazy(() => import("./pages/copyright-and-links"))
);
const FanClub = Loadable(lazy(() => import("./pages/fan-club")));
const Contact = Loadable(lazy(() => import("./pages/contact")));
const OfficialSupporterApplication = Loadable(
  lazy(() => import("./pages/official-supporter-application"))
);
const GoodsStore = Loadable(lazy(() => import("./pages/goods-store")));
const Pdf = Loadable(lazy(() => import("./pages/pdf")));
const EmailCompleted = Loadable(lazy(() => import("./pages/email-completed")));
const NotFound = Loadable(lazy(() => import("./pages/404")));
const EmailChecked = Loadable(lazy(() => import("./pages/email-checked")));

export default App;
