import React, { useMemo } from "react";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material";
import palette from "src/theme/palette";
import typography from "src/theme/typography";
import breakpoints from "src/theme/breakpoints";
import customShadows from "src/theme/shadows";

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette,
      customShadows,
      typography,
      breakpoints,
    }),
    []
  );

  const theme = createTheme(themeOptions);

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export default ThemeProvider;
