import { TypographyOptions } from "@mui/material/styles/createTypography";

const pxToRem = (value: number) => `${value / 16}rem`;

const responsiveFontSizes = ({
  sm,
  md,
  lg,
}: {
  sm: number;
  md: number;
  lg: number;
}) => ({
  "@media (max-width:1300px)": { fontSize: pxToRem(lg) },
  "@media (max-width:900px)": {
    fontSize: pxToRem(md),
  },
  "@media (max-width:600px)": { fontSize: pxToRem(sm) },
});

const typography: TypographyOptions = {
  fontFamily: `"メイリオ", Meiryo, "游ゴシック", YuGothic, "Droid Sans",
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "ＭＳ ゴシック",
    sans-serif, FontAwesome`,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(55),
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 22, md: 36, lg: 44 }),
  },
  h2: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(50),
    ...responsiveFontSizes({ sm: 20, md: 32, lg: 42 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(44),
    ...responsiveFontSizes({ sm: 18, md: 30, lg: 40 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(33),
    ...responsiveFontSizes({ sm: 16, md: 24, lg: 28 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 22 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 14 }),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(13),
    textTransform: "capitalize",
  },
};

export default typography;
