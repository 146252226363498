interface CustomShadowOptions {
  card: string;
  smCard: string;
  button: string;
  number: string;
  resultBorder: string;
  xsResultBorder: string;
  nameCard: string;
  pdfBorder: string;
  blueCard: string;
  blueTitle: string;
  smBlueTitle: string;
  member: string;
  mdMember: string;
  gameBorder: string;
  sponsorBorder: string;
  bannerTitle: string;
  boxShadow: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    customShadows: CustomShadowOptions;
  }
  interface ThemeOptions {
    customShadows?: CustomShadowOptions;
  }
}

const customShadows: CustomShadowOptions = {
  card: "4px 4px 4px 0px rgba(0, 0, 0, 0.15)",
  smCard: "2.2px 2.2px 2.2px 0px rgba(0, 0, 0, 0.15)",
  button: "0.5px 0.5px 1px 0px rgba(0, 0, 0, 0.25)",
  number: "10px 10px 10px rgb(12,39,101)",
  resultBorder: "3px solid rgb(197,197,197)",
  xsResultBorder: "1.5px solid rgb(197,197,197)",
  nameCard: "10px 10px 10px rgb(12,39,101)",
  pdfBorder: "1px solid #123C9B",
  blueCard: "5px 5px 5px grey",
  blueTitle: "2vw solid #123C9B",
  member: "1px solid #251E1C",
  mdMember: "0.4px solid #251E1C",
  smBlueTitle: "3vw solid #123C9B",
  gameBorder: "1px solid #000",
  sponsorBorder: "1px solid #EBEBEB",
  bannerTitle: "5px 5px 5px rgba(0, 0, 0, 0.65)",
  boxShadow:
    "3.200000047683716px 3.200000047683716px 3.200000047683716px 0px rgba(0, 0, 0, 0.25)",
};

export default customShadows;
