declare module "@mui/material/styles/createPalette" {
  interface GradientsPaletteOptions {
    primary: string;
    info: string;
    title: string;
    bannerBgColor: string;
    bannerTitle: string;
  }

  interface Palette {
    gradients: GradientsPaletteOptions;
  }

  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
  }
}

const GRADIENTS = {
  primary: "linear-gradient(180deg, #ADACAC 0%, #DCDCDC 47.92%, #AEAEAE 100%)",
  info: "linear-gradient( 180deg, #C89C00 0%, #FFDA56 49.99%, #C89C00 100%)",
  title: "linear-gradient(to top, #D9D9D9 0%, #D9D9D9 100%)",
  bannerBgColor: "linear-gradient(180deg, #00216C 0%, #123C9B 49.48%, #00216C 100%)",
  bannerTitle:
    "linear-gradient(180deg, #C4A73F 0%, #FFFBD4 25%, #F8F0C2 30.00%, #E7D595 41.00%, #CBAA4E 55.00%, #C29C36 60.00%, #ECDD91 86.00%, #EFE196 87.00%, #FFFBD4 100%)",
};

declare module "@mui/material/styles/createPalette" {
  interface PaletteColor {
    lighter: string;
  }
}

const common = {
  common: { black: "#000000", white: "#ffffff" },
  primary: {
    lighter: "#00F",
    light: "#0000FF",
    main: "#123C9B",
    dark: "#1E3490",
  },
  secondary: {
    lighter: "#F00",
    main: "#E60086",
    light: "#FF0000",
  },
  error: {
    main: "#FF0000",
    lighter: "",
  },
  grey: {
    "100": "#EBEBEB",
    "200": "#CBCBCB",
    "400": "#808080",
    "500": "#F8F8F8",
    "600": "#B5B5B5",
    "700": "#4E4E4E",
    "800": "#B1B1B1",
  },
  info: {
    light: "#6E90DD",
    main: "#334E96",
    lighter: "",
    darker: "",
  },
  background: {
    default: "#8192C5",
  },
  gradients: GRADIENTS,
};

const palette = { ...common };

export default palette;
